import Vue from "vue";

const navig = [
  {
    title: 'Panel główny',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Moje materiały',
    route: 'my-courses',
    icon: 'BookOpenIcon',
  },
  {
    title: 'Sklep',
    route: 'shop',
    icon: 'ShoppingCartIcon',
  },
  {
    title: 'Moje rabaty',
    route: 'discounts',
    icon: 'DollarSignIcon',
  },
]

export default navig
