<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="notificationsCount"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Powiadomienia
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      v-if="notifications.length"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-dropdown-item href="#"
        v-for="notification in notifications"
        :key="notification.id"
        @click="setAsRead(notification.id)"
        class="presentation"
      >
        <p class="media-heading mb-0">
          <span class="font-weight-bolder" v-if="!notification.read">
            {{ notification.title }}
          </span>
          <span class="font-weight-normal" v-if="notification.read">
            {{ notification.title }}
          </span>
        </p>
        <div>
          <small class="notification-text">{{ notification.course_name }}</small>
          <small class="notification-text float-right" v-if="isOld(notification.publish_at) == false">{{ notification.publish_at }}</small>
        </div>
      </b-dropdown-item>

    </vue-perfect-scrollbar>

    <!-- Cart Footer -->

    <b-dropdown-item class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        to="/powiadomienia"
      >
        Czytaj wszystkie powiadomienia
      </b-button>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox, BDropdownItem,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import router from "@/router";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      notifications: [],
    }
  },
  async mounted() {
    const response = await this.$store.getters.StateUser();
    this.$echo.private(`users.${response.data.user.id}.notifications`).listen('NotificationEvent', (payload) => {
      this.notifications.push(payload.notification);
    });
  },
  computed: {
    notificationsCount() {
      return this.notifications.filter(function(item) {
        return !item.read;
      }).length
    },
  },
  created() {
    this.fetchNotifications()
  },
  methods: {
    isOld(date) {
      // current date
      const current_date = new Date();

      // need to remake date string into mm/dd/yyyy format
      const notification_arr = date.split('.');
      const notification_date = new Date(notification_arr[1] + "/" + notification_arr[0] + "/" + notification_arr[2]);

      // if difference in dates is more than 30 days - return true
      const diffTime = current_date - notification_date;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if(diffDays > 30)
        return true;
      return false;
    },
    fetchNotifications() {
      this.$http.get(`${this.$store.state.adminDomain}/api/my/notifications?perPage=1000`).then(response => {
        this.notifications = response.data.notifications;
      })
    },
    setAsRead(id) {
      this.$http.post(`${this.$store.state.apiDomain}/api/my/notifications/setAsRead`, { notification_id: id }).then(response => {
        this.fetchNotifications();
        router.push({ name: 'notifications', query: { id: id } })
      })
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      perfectScrollbarSettings,
    }
  },
}
</script>

<style>

</style>
