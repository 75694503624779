<template>
  <div class="navbar-container d-flex content align-items-center">

    <div v-if="showLogo" class="align-items-center justify-content-center d-flex d-xl-none">
      <b-img
          :src="appLogoImage"
          alt="logo"
          style="width: 120px;"
      />
    </div>
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
     <li class="nav-item" v-if="showMenu">
        <b-link
          class="nav-link"
          :class="{pulse: isPulsating}"
          @click="toggleMenu"
        >
          <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM17 17.25H7C6.59 17.25 6.25 16.91 6.25 16.5C6.25 16.09 6.59 15.75 7 15.75H17C17.41 15.75 17.75 16.09 17.75 16.5C17.75 16.91 17.41 17.25 17 17.25ZM17 12.75H7C6.59 12.75 6.25 12.41 6.25 12C6.25 11.59 6.59 11.25 7 11.25H17C17.41 11.25 17.75 11.59 17.75 12C17.75 12.41 17.41 12.75 17 12.75ZM17 8.25H7C6.59 8.25 6.25 7.91 6.25 7.5C6.25 7.09 6.59 6.75 7 6.75H17C17.41 6.75 17.75 7.09 17.75 7.5C17.75 7.91 17.41 8.25 17 8.25Z" fill="#20ad95"/>
          </svg>
        </b-link>
      </li>
      <li class="nav-item" v-if="!showLogo">
        <b-link
            class="nav-link"
            @click="goBack"
        >
          <feather-icon
              icon="ChevronLeftIcon"
              size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <notification-dropdown />
      <cart-dropdown v-if="isWebview" />
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ userData.user.name }}
            </p>
            <span class="user-status">Klient</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/chemmaster-user-avatar.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          to="/ustawienia"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Moje ustawienia</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          to="/powiadomienia"
        >
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>Powiadomienia</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Wyloguj</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BImg,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import useJwt from '@/auth/jwt/useJwt'
import CartDropdown from '@/layouts/components/CartDropdown'
// import NotificationDropdown from '@core/layouts/components/app-navbar/components/NotificationDropdown'
import NotificationDropdown from '@/layouts/components/NotificationDropdown.vue'
import store from '@/store'
import Vue from "vue";
import {$themeConfig} from "@themeConfig";

const { appLogoImage } = $themeConfig.app

export default {
  components: {
    BImg,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      userData: {},
      isWebview: Vue.prototype.$workbox !== null,
      appLogoImage,
    }
  },
  computed: {
    showMenu() {
      return this.$route.name === 'single-course'
    },
    showLogo() {
      return this.$route.name === 'home'
    },
    isPulsating() {
      // eslint-disable-next-line camelcase
      const course_id = this.$store.getters['app/getCurrentCourse'].id

      // eslint-disable-next-line camelcase
      return localStorage.getItem(`disablePulsateMenu_${course_id}`) === null
    },
  },
  created() {
    this.userData = JSON.parse(localStorage.getItem('userData'))
  },
  methods: {
    logout() {
      useJwt.logout()
      this.$router.push({ name: 'login' })
    },
    toggleMenu() {
      this.isPulsating = false
      // eslint-disable-next-line camelcase
      const course_id = this.$store.getters['app/getCurrentCourse'].id

      // eslint-disable-next-line camelcase
      localStorage.setItem(`disablePulsateMenu_${course_id}`, 'true')
      this.toggleVerticalMenuActive()
    },
    goBack() {
      window.history.back()
    }
  },
}
</script>

<style>
.pulse:before {
  position: relative;
  font-weight: bold;
  -webkit-animation-name: pulsate;
  -webkit-animation-duration: 2s;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
}
.pulse svg{
  z-index: 1;
  display: inline-block;
  position: relative;
}
.pulse:before{
  content: " ";
  background: #ea5455;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: block;
  position: absolute;
  z-index: 0;
  top: 50%;
  margin-top: -20px;
  left: 50%;
  margin-left: -20px;

}

@-webkit-keyframes pulsate {
  0% { opacity: 0.0}
  10% { opacity: .20}
  20% { opacity: .40 }
  30% { opacity: .60 }
  40% { opacity: .80 }
  50% { opacity: 1.0}
  60% { opacity: .80}
  70% { opacity: .60}
  80% { opacity: .40}
  90% { opacity: .20}
  100% { opacity: 0.0}
}
</style>